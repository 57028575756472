import { LETTERS_M } from "/src/js/CONSTs"
/*  */
export class Decipher {
    constructor({
        target,
        words,
        detector = null,
        detectorType,
        autoCooldown = 0,
        cooldown = 0,
        attempts = 3,
    }) {
        this.words = []
        for (const word of words) {
            this.words.push(word.split(""))
        }

        this.target = target
        this.detector = detector
        this.detectorType = detectorType

        this.cooldown = cooldown
        this.autoCooldown = autoCooldown
        this.attempts = attempts

        this.wordIndex = 0

        this.build()
    }
    build() {
        if (this.detectorType == "auto") {
            this.trigger()
        } else {
            this.detector.addEventListener(this.detectorType, () =>
                this.trigger()
            )
        }
    }
    trigger() {
        let iteration = 0

        let attempt = 0

        let test = []

        let interval = setInterval(() => {
            // Get random letter
            let letter = LETTERS_M[Math.floor(Math.random() * 26)]
            // Modify innerText
            this.target.innerText = test.join("") + letter
            // Add attempt
            attempt++
            // Check attempt
            if (attempt == this.attempts) {
                test.push(this.words[this.wordIndex][iteration])
                attempt = 0
                iteration++
                this.target.innerText = test.join("")
            }
            // Check if the word is finished
            if (iteration >= this.words[this.wordIndex].length) {
                clearInterval(interval)
                this.wordIndex++
                if (this.wordIndex >= this.words.length) this.wordIndex = 0
                // Restart if auto
                if (this.detectorType == "auto") {
                    let autoInterval = setInterval(() => {
                        this.trigger()
                        clearInterval(autoInterval)
                    }, this.autoCooldown)
                }
            }
        }, this.cooldown)
    }
}
