import { Decipher } from "/src/js/classes/Decipher"
/*  */
// Get folders
const folders = document.querySelectorAll(".folder")
// Add dyslexic effect
for (const folder of folders) {
    new Decipher({
        target: folder.querySelector(".name .word"),
        detector: folder,
        detectorType: "mouseover",
        cooldown: 30,
        attempts: 3,
        words: [folder.querySelector(".name .word").innerText],
        word: folder.querySelector(".name .word").innerText,
    })
}
// Add event listeners
for (const folder of folders) {
    // Click listener
    folder.addEventListener("click", () => {
        // Remove active class from each folder
        for (const fd of folders) {
            if (fd == folder) continue
            fd.classList.add("hidden")
            fd.classList.remove("active")
        }
        // setTimeout(() => {
        // Add active class
        folder.classList.add("active")
        // }, 1000)
    })
}
// Get names
const names = document.querySelectorAll(".folder .back")
// Add event listeners
for (const name of names) {
    // Click listener
    name.addEventListener("click", (e) => {
        // Stop event propagation
        e.stopPropagation()
        // Remove classes from each folder
        for (const fd of folders) {
            fd.classList.remove("active")
            fd.classList.remove("hidden")
        }
    })
}
// Tilt effect
/* const intensityX = 15
const intensityY = 15
document.addEventListener("mousemove", (e) => {
    const mouseX = e.clientX
    const mouseY = e.clientY
    for (const folder of folders) {
        const folderRect = folder
            .querySelector(".square")
            .getBoundingClientRect()
        const folderX = folderRect.left + folderRect.width / 2
        const folderY = folderRect.top + folderRect.height / 2

        const rotX = (((mouseX - folderX) * intensityX) / folderX) * -1
        const rotY = ((mouseY - folderY) * intensityY) / folderY

        // folder.style.setProperty("--rotX", `${rotX}deg`)
        // folder.style.setProperty("--rotY", `${rotY}deg`)
        // folder.querySelector(".content").style.transform= `rotateY(${rotX}deg) rotateX(${rotY}deg)`
        folder.style.transform = `rotateY(${rotX}deg) rotateX(${rotY}deg)`
    }
}) */
